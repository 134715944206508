import { useQuery } from '@tanstack/react-query';
import {
  CashbackFilter,
  CoinTransactionParams,
  DateRangeParams,
  FlaggedOrdersParams,
  FoodItemParams,
  PaginationParams,
  PilotsParams,
} from '../types';
import {
  brandApi,
  categoriesApi,
  couponsApi,
  loyaltyApi,
  mealsApi,
  pilotsApi,
  ordersApi,
  kitchensApi,
} from '../api';

export const useFetch = () => {
  /// foooood
  const useFetchFoodItem = <T>(
    id?: string,
    params?: FoodItemParams,
    options?: any
  ) =>
    useQuery<T>(
      ['food-items', id, params],
      () => mealsApi.getFoodItems(id, params) as T,
      options
    );

  const useFetchFoodGroup = <T>(
    id?: string,
    params?: FoodItemParams,
    options?: any
  ) =>
    useQuery<T>(
      ['food-group', id, params],
      () => mealsApi.getFoodGroups(id, params) as T,
      options
    );

  const useFetchOptions = (params?: FoodItemParams, options?: any) =>
    useQuery(['options', params], () => mealsApi.getOptions(params), options);

  /// brand
  const useFetchBrand = <T>(slug?: string, options?: any) =>
    useQuery<T>(['brands', slug], () => brandApi.getBrand(slug) as T, options);

  /// category
  const useFetchCategory = <T>(slug?: string, options?: any) =>
    useQuery<T>(
      ['category', slug],
      () => categoriesApi.getCategory(slug) as T,
      options
    );

  const useFetchAllPilots = (
    slug?: string,
    params?: PilotsParams,
    options?: any
  ) =>
    useQuery(
      ['pilots', params, slug],
      () => pilotsApi.getAllPilots(slug, params),
      options
    );

  const useFetchPilotSummary = (options?: any) =>
    useQuery(['pilots-summary'], () => pilotsApi.getPilotsSummary(), options);

  // coupons
  const useFetchCashback = (options?: any) =>
    useQuery(['cashback'], () => couponsApi.getActiveCashback(), options);

  const useFetchCashbackTransactions = (
    params?: CashbackFilter,
    options?: any
  ) =>
    useQuery(
      ['cashback-transactions'],
      () => couponsApi.getCashbackTransactions(params),
      options
    );

  const useFetchLoyaltyDashboard = (params?: DateRangeParams, options?: any) =>
    useQuery(
      ['loyalty-dashboard', params],
      () => loyaltyApi.getLoyaltyDashboard(params),
      options
    );

  const useFetchCoinTransactions = (
    params?: CoinTransactionParams,
    options?: any
  ) =>
    useQuery(
      ['coin-transactions', params],
      () => loyaltyApi.listCoinTransaction(params),
      options
    );

  const useFetchLoyaltyTiers = (params?: PaginationParams, options?: any) =>
    useQuery(
      ['loyalty-tiers', params],
      () => loyaltyApi.getAllTier(params),
      options
    );

  const useFetchAllActions = (params?: PaginationParams, options?: any) =>
    useQuery(
      ['loyalty-actions', params],
      () => loyaltyApi.getAllActions(params),
      options
    );

  const useFetchBudgets = (options?: any) =>
    useQuery(['budgets'], () => couponsApi.getAllBudgets(), options);

  const useFetchFlaggedOrders = (params?: FlaggedOrdersParams, options?: any) =>
    useQuery(
      ['flagged-orders', params],
      () => ordersApi.getFlaggedOrders(params),
      options
    );

  const useFetchKitchenRequests = (params?: PaginationParams, options?: any) =>
    useQuery(
      ['kitchen-requests', params],
      () => kitchensApi.getKitchenRequests(params),
      options
    );

  return {
    useFetchBrand,
    useFetchOptions,
    useFetchBudgets,
    useFetchFoodItem,
    useFetchCategory,
    useFetchCashback,
    useFetchFoodGroup,
    useFetchAllPilots,
    useFetchAllActions,
    useFetchPilotSummary,
    useFetchLoyaltyTiers,
    useFetchFlaggedOrders,
    useFetchKitchenRequests,
    useFetchCoinTransactions,
    useFetchLoyaltyDashboard,
    useFetchCashbackTransactions,
  };
};
