import { KitchenRole } from '../types';

const kitchenLabels = [
  { value: 'OFFICE', option: 'Office' },
  { value: 'HOME', option: 'Home' },
  { value: 'OTHER', option: 'Other' },
  { value: 'WORK', option: 'Work' },
  { value: 'MAIN_KITCHEN', option: 'Main Kitchen' },
  { value: 'BRANCH_KITCHEN', option: 'Branch Kitchen' },
] as { value: string; option: string }[];

const kitchenStatus = [
  { value: 'ACTIVE', option: 'Active' },
  { value: 'INACTIVE', option: 'Inactive' },
  { value: 'CLOSED', option: 'Closed' },
  { value: 'FLAGGED', option: 'Flagged' },
] as { value: string; option: string }[];

export const StaffRole = [
  { value: 'DELIGHT_OFFICER', option: 'DELIGHT_OFFICER' },
  { value: 'CHEF', option: 'CHEF' },
  { value: 'FINANCE', option: 'FINANCE' },
  { value: 'OWNER', option: 'OWNER' },
  { value: 'SERVER', option: 'SERVER' },
  { value: 'LOGISTICS', option: 'LOGISTICS' },
  { value: 'CUSTOMER_SUCCESS', option: 'CUSTOMER_SUCCESS' },
] as { value: KitchenRole; option: KitchenRole }[];

export const VendorStaffRole = [
  { value: 'FINANCE', option: 'FINANCE' },
  { value: 'OWNER', option: 'OWNER' },
] as { value: KitchenRole; option: KitchenRole }[];

const kitchensColumns = [
  {
    Header: 'Kitchen Name',
    accessor: 'name',
  },
  {
    Header: 'Date Created',
    accessor: 'createdAt',
  },
  {
    Header: 'Action',
  },
];

const defaultHours = [
  { weekday: 1, isOpen: false },
  { weekday: 2, isOpen: false },
  { weekday: 3, isOpen: false },
  { weekday: 4, isOpen: false },
  { weekday: 5, isOpen: false },
  { weekday: 6, isOpen: false },
  { weekday: 7, isOpen: false },
];

const mapDayOfWeek = {
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
  7: 'Sun',
};

const kitchenLocation = {
  latitude: 6.4338405,
  longitude: 3.4688427,
};

export {
  kitchenLabels,
  kitchenStatus,
  kitchensColumns,
  defaultHours,
  mapDayOfWeek,
  kitchenLocation,
};

export const kitchenTypes = [
  { name: '500chow kitchens', value: '500chow' },
  { name: 'Other kitchens', value: 'others' },
];
