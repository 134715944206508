import React, { SyntheticEvent, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { kitchensColumns } from '../../../../constants';
import { useAppDispatch, useAppSelector, useFetch } from '../../../../hooks';
import { getAllKitchens, kitchensSelector } from '../../../../store/slices';
import { LogoutLoader, NoData, Paginator, Table } from '../../../components';
import { backArrow } from '../../../../assets/svg';
import Modal from 'react-modal';
import { kitchensApi } from '../../../../api';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const VendorKitchens = () => {
  const dispatch = useAppDispatch();
  const { kitchens, loading } = useAppSelector(kitchensSelector);

  const [modal, setModal] = React.useState(false);

  const navigate = useNavigate();

  const kitchenPage = (id: number) => {
    navigate(`/dashboard/kitchen/${id}`);
  };

  const createKitchen = (
    id?: number,
    e?: SyntheticEvent<HTMLButtonElement>
  ) => {
    e && e.stopPropagation();
    typeof id === 'number'
      ? navigate(`/dashboard/kitchens/edit/${id}`)
      : navigate(`/dashboard/kitchens/create`);
  };

  useEffect(() => {
    kitchens && kitchens.length === 0 && dispatch(getAllKitchens());
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <>
      <div className="body1">
        <button
          className="inline-flex items-center space-x-2 text-sm relative z-20"
          onClick={() => navigate(-1)}
        >
          <img src={backArrow} alt="back" />
          <span className="semibold">Back</span>
        </button>
        <div className="header1">
          <div className="relative z-20">
            <h1 className="semibold text-xl">Vendor Kitchens</h1>
            <button
              className="text-primary-300 font-semibold hover:underline cursor-pointer"
              onClick={() => setModal(true)}
            >
              View pending requests
            </button>
          </div>
        </div>

        {kitchens.filter((kitchen) => kitchen.isVendorEnabled).length > 0 ? (
          <Table
            columns={kitchensColumns}
            data={kitchens.filter((kitchen) => kitchen.isVendorEnabled)}
            editMethod={createKitchen}
            tableFor={'kitchens'}
            openPage={kitchenPage}
          />
        ) : (
          !loading && (
            <NoData
              title="No vendor kitchen"
              description="There are no vendor kitchens yet"
            />
          )
        )}
      </div>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-8/12 h-10/12 default-modal`}
        overlayClassName="modal-overlay"
      >
        <PendingRequests />
      </Modal>
    </>
  );
};

export default VendorKitchens;

const PendingRequests = () => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const [loading, setLoading] = React.useState({ state: false, code: '' });
  const [page, setPage] = React.useState(1);

  const { useFetchKitchenRequests } = useFetch();
  const { data, isLoading } = useFetchKitchenRequests({ page });

  const handlePageClick = (data: any) => {
    const page = data.selected + 1;
    setPage(page);
  };

  const acceptRequest = async (code: string) => {
    setLoading({ state: true, code });

    try {
      await kitchensApi.approveKitchenRequest(code);

      toast.success('Kitchen request approved');
      queryClient.refetchQueries(['kitchen-requests']);
      dispatch(getAllKitchens());
    } catch (error) {
    } finally {
      setLoading({ state: false, code: '' });
    }
  };

  return (
    <div className="bg-white p-6 h-full">
      <p className="text-2xl bold">Pending Requests</p>

      {isLoading ? (
        <div className="flex w-full h-full justify-center items-center">
          <LogoutLoader heightWidth="h-10 w-10" />
        </div>
      ) : (
        <div className="mt-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
            {data &&
            data.data &&
            data?.data?.filter(({ status }) => status === 'PENDING')?.length >
              0 ? (
              data?.data
                ?.filter(({ status }) => status === 'PENDING')
                ?.map(
                  ({
                    code,
                    name,
                    email,
                    logoUrl,
                    address,
                    productType,
                    description,
                    phoneNumber,
                    personOfContact,
                  }) => (
                    <div
                      key={code}
                      className="rounded border border-neutral-300 p-4"
                    >
                      <div className="flex items-center space-x-3">
                        {logoUrl && (
                          <img
                            className="h-5 w-5 rounded-full"
                            src={logoUrl}
                            alt="logo"
                          />
                        )}
                        <div>
                          <p className="text-lg">{name}</p>
                          <p className="text-xs">{description}</p>
                        </div>
                      </div>
                      {productType && (
                        <p className="mt-3">
                          <span className="bold">Product category:</span>{' '}
                          {productType.name}
                        </p>
                      )}
                      {productType && (
                        <p className="mt-3">
                          <span className="bold">Product type:</span>{' '}
                          {productType.businessCategory.name}
                        </p>
                      )}
                      <p className="mt-3">
                        <span className="bold">Person of contact:</span>{' '}
                        {personOfContact}
                      </p>
                      <p className="mt-3">
                        <span className="bold">Address:</span> {address.address}
                      </p>
                      <p className="mt-3">
                        <span className="bold">Phone number:</span>{' '}
                        {phoneNumber}
                      </p>
                      <p className="mt-3">
                        <span className="bold">Email:</span> {email}
                      </p>
                      <div className="flex justify-end mt-8">
                        <button
                          className="text-primary-700 bold text-sm px-4 border border-primary-700 py-2 rounded"
                          onClick={() => acceptRequest(code)}
                        >
                          {loading.state && loading.code === code ? (
                            <LogoutLoader />
                          ) : (
                            'Accept'
                          )}
                        </button>
                      </div>
                    </div>
                  )
                )
            ) : (
              <div className="w-full flex justify-center mt-10">
                <NoData
                  title="No pending requests"
                  description="There are no pending requests yet"
                />
              </div>
            )}
          </div>

          <Paginator
            click={handlePageClick}
            totalPages={data?.pagination?.totalPages || 1}
            selected={data?.pagination?.currentPage ?? 1}
            hasNext={data?.pagination?.hasNext || false}
          />
        </div>
      )}
    </div>
  );
};
