import axios from 'axios';
import { IKitchensApi } from '../types/kitchen.types';
import { instance } from './instance';
import { stringifyParameters } from '../helpers';

const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;

export const kitchensApi: IKitchensApi = {
  getAllKitchens: async () => await instance.get('/admin/kitchen/all/'),

  getKitchen: async (id) => await instance.get(`/admin/kitchen/${id}/`),

  getPlusCode: async (lat, lng) =>
    await axios.get(
      `https://plus.codes/api?address=${lat},${lng}&ekey=${apiKey}`
    ),

  createKitchen: async (payload) =>
    await instance.post('/admin/kitchen/create/', payload),

  updateKitchen: async (payload, id) =>
    await instance.put(`/admin/kitchen/${id}/`, payload),

  inviteStaff: async (kitchenId, payload) =>
    await instance.post(`/kitchen/${kitchenId}/invites`, payload),

  getKitchenRequests: async (params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/admin/kitchen-requests/all/${payload}`);

    return data.data;
  },

  approveKitchenRequest: async (code) =>
    (await instance.post(`/admin/kitchen-requests/${code}/approve`)).data,
};
