import { useNavigate, useSearchParams } from 'react-router-dom';
import { FilterCoupons } from '.';
import { editButton } from '../../../../assets/svg';
import {
  Cashback as CashbackType,
  CashbackFilter,
  CouponFilter,
} from '../../../../types';
import React from 'react';
import { useFetch } from '../../../../hooks';
import Modal from 'react-modal';
import {
  PrimaryButton,
  PrimaryInput,
  PrimarySelect,
  SearchWithQuery,
  Table,
} from '../../../components';
import { cashbackColumns, naira, promotionStatus } from '../../../../constants';
import { couponsApi } from '../../../../api';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import { useQueryClient } from '@tanstack/react-query';

const Cashback = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { useFetchCashback, useFetchCashbackTransactions } = useFetch();
  const { data: cashback, isLoading } = useFetchCashback();
  const { data: transactions } = useFetchCashbackTransactions();

  const [modal, setModal] = React.useState(false);
  const [showFilters, setShowFilters] = React.useState(false);

  const filter = (filters: CashbackFilter) => {
    searchParams.set('page', '1');

    navigate(`/dashboard/coupons/cashback?${searchParams.toString()}`);
  };

  const resetFilters = () => {
    setShowFilters(false);
    navigate(`/dashboard/coupon/cashback`);
  };

  React.useEffect(() => {
    if ((cashback === null || cashback === undefined) && !isLoading) {
      setModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cashback]);

  return (
    <div className="body1">
      <div className="header1">
        <div className="border w-full rounded-lg border-neutral-600 p-4">
          <div className="flex justify-between py-2 border-b border-neutral-600">
            <h1 className="bold text-xl">Cashback</h1>
            <button onClick={() => setModal(true)}>
              <img src={editButton} alt="edit-button" />
            </button>
          </div>

          {cashback ? (
            <div className="grid grid-cols-2 gap-2 mt-4">
              <div>
                <p className="uppercase bold">Description</p>
                <div className="border border-neutral-300 px-2 py-4 rounded mt-2">
                  <p>{cashback.description ?? '-'}</p>
                </div>
              </div>

              <div>
                <p className="uppercase bold">Percentage</p>
                <div className="border border-neutral-300 px-2 py-4 rounded mt-2">
                  <p className="uppercase">{cashback?.percentage}% cashback</p>
                </div>
              </div>

              <div>
                <p className="uppercase bold">Active?</p>
                <div className="border border-neutral-300 px-2 py-4 rounded mt-2">
                  <p className="uppercase">
                    {cashback?.isActive ? 'YES' : 'NO'}
                  </p>
                </div>
              </div>

              <div>
                <p className="uppercase bold">Flat amount</p>
                <div className="border border-neutral-300 px-2 py-4 rounded mt-2">
                  <p className="uppercase">
                    {`${naira}${numeral(cashback?.flatAmount).format('0,0')}`}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p className="mt-4">No cashback exists</p>
          )}
        </div>
      </div>

      <div>
        {showFilters && (
          <div
            onClick={() => setShowFilters(false)}
            className="absolute bg-black bg-opacity-20 inset-0 h-full w-full z-10"
          />
        )}

        <div className="flex w-full items-end justify-between">
          <div>
            <SearchWithQuery<CouponFilter>
              params={searchParams}
              paramTitle="useUserName"
              placeholder="Search for a user"
              applyFilter={filter}
            />
          </div>

          <div className="flex space-x-3">
            <button
              type="button"
              onClick={resetFilters}
              className="text-sm text-primary-brown"
            >
              Reset
            </button>

            <FilterCoupons
              usedUsers
              params={searchParams}
              applyFilters={filter}
              showFilters={showFilters}
              resetFilters={resetFilters}
              setShowFilters={setShowFilters}
            />
          </div>
        </div>

        <Table
          columns={cashbackColumns}
          data={transactions?.cashBackUses ?? []}
          tableFor={'transactions'}
        />
      </div>

      <Modal
        isOpen={modal}
        onRequestClose={() => setModal(cashback ? false : true)}
        ariaHideApp={false}
        className={`w-11/12 sm:w-[450px] default-modal`}
        overlayClassName="modal-overlay"
      >
        <EditCashback
          cashback={cashback}
          closeModal={() => setModal(cashback ? false : true)}
        />
      </Modal>
    </div>
  );
};

export default Cashback;

const EditCashback = ({
  cashback,
  closeModal,
}: {
  cashback?: CashbackType;
  closeModal: () => void;
}) => {
  const queryClient = useQueryClient();

  const { useFetchBudgets } = useFetch();
  const { data: budgets } = useFetchBudgets();

  const [payload, setPayload] = React.useState<CashbackType>({
    description: '',
    percentage: 0,
    flatAmount: 0,
    isActive: false,
  });

  const onGetInputValue = (
    event: React.SyntheticEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const value = event.currentTarget.value;
    const name = event.currentTarget.name;

    if (name === 'description' || name === 'type') {
      return setPayload({
        ...payload,
        [name]: value,
      });
    }

    if (name === 'isActive')
      return setPayload({
        ...payload,
        [name]: value === '0' ? false : true,
      });

    setPayload({
      ...payload,
      [name]: parseInt(value),
    });
  };

  const updateCashback = async () => {
    try {
      if (cashback) {
        await couponsApi.updateCashback(payload);
        toast.success('Cashback updated');
      } else {
        await couponsApi.createCashback(payload);
        toast.success('Cashback created');
      }

      queryClient.refetchQueries(['cashback']);
      closeModal();
    } catch (error) {}
  };

  React.useEffect(() => {
    if (cashback) {
      const { id, budget, createdAt, updatedAt, ...rest } = cashback;
      setPayload({ ...rest, cashbackId: id });
    }
  }, [cashback]);

  return (
    <div className="p-6 bg-white">
      <p className="bold text-xl">Setup Cashback</p>

      <PrimaryInput
        name="description"
        className="w-full"
        value={payload.description}
        onChange={onGetInputValue}
        label="Enter cashback description"
        placeholder="Describe the cashback"
      />

      <PrimarySelect
        onChange={onGetInputValue}
        options={promotionStatus}
        optionLabel="name"
        value={payload?.isActive ? 1 : 0}
        optionValue="value"
        label="Is the cashback active?"
        name="isActive"
        placeholder="Select cashback status"
        className="w-full"
      />

      <PrimarySelect
        onChange={onGetInputValue}
        options={budgets ?? []}
        optionLabel="name"
        value={payload?.budgetId}
        optionValue="id"
        label="What budget should be used?"
        name="budgetId"
        placeholder="Select budget"
        className="w-full"
      />

      <PrimarySelect
        onChange={onGetInputValue}
        options={[{ id: 'card', name: 'Card' }]}
        optionLabel="name"
        value={payload?.type}
        optionValue="id"
        label="What is the funding type?"
        name="type"
        placeholder="Select type"
        className="w-full"
      />

      <PrimaryInput
        max={100}
        type="number"
        name="percentage"
        className="w-full"
        value={payload.percentage}
        onChange={onGetInputValue}
        placeholder="Enter cashback value"
        label="Enter cashback percentage value (max 100)"
      />

      <PrimaryInput
        type="number"
        name="flatAmount"
        className="w-full"
        label="Flat amount"
        value={payload.flatAmount}
        onChange={onGetInputValue}
        placeholder="Enter flat amount"
      />

      <div className="mt-4">
        <PrimaryButton onClick={updateCashback} text="Setup Cashback" />
      </div>
    </div>
  );
};
