import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '..';
import { brandApi } from '../../api';
import {
  BrandReviews,
  Category,
  IBrand,
  IBrandPosition,
  ICreateBrand,
  ICreateGroup,
  IGroup,
  IMeal,
} from '../../types';

interface IBrandsState {
  loading: boolean;
  brands: IBrand[];
  brandMeals: IMeal[];
  brand: IBrand | null;
  groups: IGroup[];
  categories: Category[];
  categoryRanks: Category[];
  group: IGroup | null;
  reviews: BrandReviews | null;
  brandPositions: IBrandPosition[];
}

const initialState: IBrandsState = {
  loading: false,
  brands: [],
  brandMeals: [],
  brand: null,
  groups: [],
  categories: [],
  categoryRanks: [],
  group: null,
  reviews: null,
  brandPositions: [],
};

export const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createBrand.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBrand.fulfilled, (state) => {
      state.loading = false;
      toast.success('Brand created successfully.');
    });
    builder.addCase(createBrand.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllBrands.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBrands.fulfilled, (state, { payload }) => {
      state.brands = payload;
      state.loading = false;
    });
    builder.addCase(getAllBrands.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrand.pending, (state) => {
      state.loading = true;
      state.brand = null;
    });
    builder.addCase(getBrand.fulfilled, (state, { payload }) => {
      state.brand = payload as IBrand;
      state.loading = false;
    });
    builder.addCase(getBrand.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBrand.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBrand.fulfilled, (state) => {
      state.loading = false;
      toast.success('Brand updated successfully.');
    });
    builder.addCase(updateBrand.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrandGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrandGroups.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.groups = payload;
    });
    builder.addCase(getBrandGroups.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createGroup.fulfilled, (state) => {
      state.loading = false;
      toast.success('Group created successfully.');
    });
    builder.addCase(createGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGroup.fulfilled, (state) => {
      state.loading = false;
      toast.success('Group updated successfully.');
    });
    builder.addCase(updateGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getGroup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGroup.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.group = payload;
    });
    builder.addCase(getGroup.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrandMeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrandMeals.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.brandMeals = payload;
    });
    builder.addCase(getBrandMeals.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getAllGroups.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllGroups.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.groups = payload;
    });
    builder.addCase(getAllGroups.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrandReviews.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrandReviews.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.reviews = payload;
    });
    builder.addCase(getBrandReviews.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrandCategories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrandCategories.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
    });
    builder.addCase(getBrandCategories.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrandCategoryRanks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrandCategoryRanks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.categoryRanks = payload;
    });
    builder.addCase(getBrandCategoryRanks.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(createBrandPosition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createBrandPosition.fulfilled, (state) => {
      state.loading = false;
      toast.success('Brand position created successfully.');
    });
    builder.addCase(createBrandPosition.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getBrandPositions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBrandPositions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.brandPositions = payload;
    });
    builder.addCase(getBrandPositions.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateBrandPosition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBrandPosition.fulfilled, (state) => {
      state.loading = false;
      toast.success('Brand position updated successfully.');
    });
    builder.addCase(updateBrandPosition.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteBrandPosition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBrandPosition.fulfilled, (state) => {
      state.loading = false;
      toast.success('Brand position deleted successfully.');
    });
    builder.addCase(deleteBrandPosition.rejected, (state) => {
      state.loading = false;
    });
  },
});

const createBrand = createAsyncThunk(
  'createBrand',
  async (params: { payload: ICreateBrand; cb?: () => void }, { dispatch }) => {
    try {
      const { payload, cb } = params;
      const { data } = await brandApi.createBrand(payload);
      cb && cb();
      dispatch(getAllBrands());
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllBrands = createAsyncThunk('getAllBrands', async () => {
  try {
    const { data } = await brandApi.getAllBrands();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getBrand = createAsyncThunk('getBrand', async (param: string) => {
  try {
    const data = await brandApi.getBrand(param);
    return data;
  } catch (error: any) {
    throw error;
  }
});

const updateBrand = createAsyncThunk(
  'updateBrand',
  async (
    params: {
      payload: ICreateBrand;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await brandApi.updateBrand(payload, id);
      cb && cb();
      dispatch(getAllBrands());
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getGroup = createAsyncThunk('getGroup', async (param: string) => {
  try {
    const { data } = await brandApi.getGroup(param);
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getBrandGroups = createAsyncThunk(
  'getBrandGroups',
  async (id: number) => {
    try {
      const { data } = await brandApi.getBrandGroups(id);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const createGroup = createAsyncThunk(
  'createGroup',
  async (params: { payload: ICreateGroup; cb?: () => void }, { dispatch }) => {
    try {
      const { payload, cb } = params;
      const { data } = await brandApi.createGroup(payload);
      cb && cb();
      dispatch(getBrandGroups(payload.brand));
      return data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateGroup = createAsyncThunk(
  'updateGroup',
  async (
    params: {
      payload: ICreateGroup;
      cb?: () => void;
      id: number;
    },
    { dispatch }
  ) => {
    try {
      const { payload, cb, id } = params;
      const { data } = await brandApi.updateGroup(payload, id);
      cb && cb();
      dispatch(getBrandGroups(payload.brand));
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getBrandMeals = createAsyncThunk(
  'getBrandMeals',
  async (slug: string) => {
    try {
      const { data } = await brandApi.getBrandMeals(slug);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getAllGroups = createAsyncThunk('getAllGroups', async () => {
  try {
    const { data } = await brandApi.getAllGroups();
    return data.data;
  } catch (error: any) {
    throw error;
  }
});

const getBrandCategories = createAsyncThunk(
  'getBrandCategories',
  async (id: number) => {
    try {
      const { data } = await brandApi.getBrandCategories(id);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getBrandCategoryRanks = createAsyncThunk(
  'getBrandCategoryRanks',
  async (id: number) => {
    try {
      const { data } = await brandApi.getBrandCategoryRanks(id);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const createBrandPosition = createAsyncThunk(
  'createBrandPosition',
  async (
    params: { payload: IBrandPosition; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { payload, cb } = params;
      const { data } = await brandApi.createBrandPosition(payload);
      cb && cb();
      dispatch(getBrandPositions(payload.brandId));
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getBrandPositions = createAsyncThunk(
  'getBrandPositions',
  async (brandId: number) => {
    try {
      const { data } = await brandApi.getBrandPositions(brandId);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const updateBrandPosition = createAsyncThunk(
  'updateBrandPosition',
  async (
    params: {
      id: number;
      payload: Partial<IBrandPosition>;
      brandId: number;
      cb?: () => void;
    },
    { dispatch }
  ) => {
    try {
      const { id, payload, brandId, cb } = params;
      const { data } = await brandApi.updateBrandPosition(id, payload);
      cb && cb();
      dispatch(getBrandPositions(brandId));
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const deleteBrandPosition = createAsyncThunk(
  'deleteBrandPosition',
  async (
    params: { id: number; brandId: number; cb?: () => void },
    { dispatch }
  ) => {
    try {
      const { id, brandId, cb } = params;
      const { data } = await brandApi.deleteBrandPosition(id);
      cb && cb();
      dispatch(getBrandPositions(brandId));
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

const getBrandReviews = createAsyncThunk(
  'getBrandReviews',
  async (id: number) => {
    try {
      const { data } = await brandApi.getBrandReviews(id);
      return data.data;
    } catch (error: any) {
      throw error;
    }
  }
);

export const brandsSelector = (state: RootState) => state.brands;
export {
  createBrand,
  getAllBrands,
  getBrand,
  updateBrand,
  getBrandGroups,
  createGroup,
  updateGroup,
  getGroup,
  getBrandMeals,
  getAllGroups,
  getBrandReviews,
  getBrandCategories,
  getBrandCategoryRanks,
  createBrandPosition,
  getBrandPositions,
  updateBrandPosition,
  deleteBrandPosition,
};

export default brandsSlice.reducer;
