import { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { collapse, logo, menu, power } from '../../../assets/svg';
import { navLinks } from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { authSelector, logout } from '../../../store/slices';
import { LogoutLoader } from '../shared';

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const { loading, user } = useAppSelector(authSelector);

  const Links = useRef<HTMLInputElement>(null);

  const [navOpen, setNavOpen] = useState<boolean>(false);
  const [navCollapsed, setNavCollapsed] = useState<boolean>(false);
  const [linksScrollable, setLinksScrollable] = useState(false);

  const logoutUser = () => {
    dispatch(logout());
  };

  const toggleSidebar = () => {
    setNavOpen((prev) => !prev);
  };

  const handleCollapse = () => {
    setNavCollapsed((prev) => !prev);
  };

  useEffect(() => {
    if (Links && Links.current) {
      setLinksScrollable(
        Links.current?.scrollHeight > Links.current?.clientHeight
      );
    }
  }, [Links]);

  return (
    <div
      className={`relative ease-in-out transition-all duration-300 z-40 flex-shrink-0 ${
        navCollapsed ? 'lg:w-[7rem]' : 'lg:w-[20rem]'
      }`}
    >
      <div
        className={`bg-black absolute bg-opacity-10 min-h-screen h-full w-screen z-40 inset-0 ${
          navOpen ? 'block lg:hidden' : 'hidden'
        }
    `}
        onClick={toggleSidebar}
      ></div>
      <div
        className={`absolute lg:static left-0 top-0 transform ease-in-out z-50 transition-all duration-500 h-full bg-neutral-900 py-6 px-4 flex flex-col justify-between flex-shrink-0 rounded-r-xl lg:rounded-none ${
          navOpen ? 'translate-x-0' : '-translate-x-[19.7rem] lg:translate-x-0'
        } ${navCollapsed ? 'lg:w-full w-[20rem]' : 'w-[20rem]'}`}
      >
        <button
          className="h-14 flex flex-col justify-center items-end space-y-1 rounded-xl pr-2 w-12 absolute top-10 z-50 -right-5 bg-neutral-900 lg:hidden"
          onClick={toggleSidebar}
        >
          <img src={menu} className="-mr-2.5 h-7" alt="menu" />
        </button>
        <div className="h-[80%] flex flex-col">
          <img src={logo} alt="logo" className="mx-auto mt-4" />
          <div
            className="flex flex-col mt-10 h-[85%] overflow-y-auto hide-scrollbar"
            ref={Links}
          >
            {navLinks.map(
              ({ name, path, icon, restrictions }) =>
                !user?.admin?.roles.some((role) =>
                  restrictions.includes(role)
                ) && (
                  <NavLink
                    to={path}
                    key={name}
                    className={({ isActive }) =>
                      `py-2 px-2.5 text-white flex items-center capitalize rounded text-sm mt-3 ${
                        isActive ? 'bg-neutral-800' : ''
                      } ${navCollapsed ? 'lg:justify-center' : ''}`
                    }
                    onClick={toggleSidebar}
                  >
                    <img src={icon} alt="icon" className="w-4" />
                    <p
                      className={`semibold transition-all truncate delay-300 ml-3 ${
                        navCollapsed
                          ? 'overflow-hidden lg:w-0 lg:opacity-0 lg:ml-0'
                          : 'block'
                      }`}
                    >
                      {name}
                    </p>
                  </NavLink>
                )
            )}
          </div>
          {linksScrollable && (
            <p className="animate-bounce text-xs text-center mt-2 text-neutral-400">
              scroll
            </p>
          )}
        </div>
        <div className="space-y-5 flex flex-col">
          <button
            onClick={handleCollapse}
            className={`text-white lg:flex items-center focus:outline-none hidden  ${
              navCollapsed ? 'justify-center' : ''
            }`}
          >
            <img
              src={collapse}
              className={`"h-6 w-6 mr-3 transform ${
                navCollapsed ? 'rotate-180' : ''
              }`}
              alt="collapse"
            />
            {!navCollapsed && (
              <span className="truncate">Collapse Sidebar</span>
            )}
          </button>
          <button
            onClick={logoutUser}
            className={`flex items-center text-white focus:outline-none ${
              navCollapsed ? 'lg:justify-center' : ''
            }`}
          >
            {loading ? (
              <div className="h-6 w-6 mr-3 flex items-center">
                <LogoutLoader />
              </div>
            ) : (
              <img className="h-6 w-6 mr-3" src={power} alt="logout" />
            )}
            <span className={`${!navCollapsed ? '' : 'lg:hidden'}`}>
              Logout
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};
