import moment, { MomentInput } from 'moment';
import numeral from 'numeral';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  useRowSelect,
  useSortBy,
  useTable,
  useGlobalFilter,
  useFilters,
  Row,
} from 'react-table';
import { matchSorter } from 'match-sorter';
import {
  deleteIcon,
  duplicate,
  edit,
  quickActionIcon,
} from '../../../assets/svg';
import { statusColors } from '../../../constants';
import { CheckboxToggle, Toggle } from './toggle';
import { usePermissions } from '../../../hooks';
import { getOptionPrice, shouldFlag } from '../../../helpers';
import { OrderFlag } from '../../features/dashboard/orders/orders';
import { IOrder } from '../../../types';

interface ITable {
  data: any;
  columns: any;
  openPage?: any;
  tableFor: string;
  editMethod?: any;
  quickAction?: any;
  paginated?: boolean;
  showSearch?: boolean;
  setSelectedRows?: any;
  selectedRows?: string[];
  containerClassName?: string;
  deleteMethod?: (key: string) => void;
  duplicateMethod?: (key: string) => void;
  getRowId?: (row: object, relativeIndex: number, parent?: any) => string;
}

function fuzzyTextFilterFn(rows: Row[], columnIds: any, filterValue: any) {
  return matchSorter(rows, filterValue, {
    keys: [(row: Row) => row.values[columnIds]],
  });
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val;

export const Table: FC<ITable> = ({
  data,
  columns,
  tableFor,
  openPage,
  paginated,
  editMethod,
  quickAction,
  selectedRows,
  setSelectedRows,
  showSearch = true,
  containerClassName,
  getRowId,
  deleteMethod,
  duplicateMethod,
}) => {
  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows: Row[], columnIds: any, filterValue: any) => {
        return rows.filter((row) => {
          const rowValue = row.values[columnIds];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds, globalFilter },
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      getRowId,
      state: {
        selectedRowIds: selectedRows,
      },
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect
  );

  const { isOwner } = usePermissions();

  const [value, setValue] = useState(globalFilter);

  const withLogo = [
    'Brand Name',
    'Category Name',
    'Kitchen Name',
    'Meal',
    'Variant',
    'Promotion Title',
    'Item name',
  ];
  const useDate = [
    'Date Created',
    'Date Updated',
    'Date & Time',
    'Start Date',
    'End Date',
    'Date',
  ];

  const openParam = {
    brands: 'slug',
    categories: 'slug',
    kitchens: 'id',
    meals: 'slug',
    orders: 'id',
    users: 'id',
    promotion: 'id',
    coupons: 'id',
    pilots: 'id',
    branch: 'id',
    foodItems: 'id',
    options: 'id',
    categoryRank: 'id',
    marketingCategories: 'name',
  };

  const editParam = {
    brands: 'slug',
    categories: 'slug',
    kitchens: 'id',
    meals: 'slug',
    orders: 'id',
    variants: 'slug',
    groups: 'slug',
    users: 'id',
    promotion: 'id',
    coupons: 'id',
    pilots: 'id',
    branch: 'id',
    foodItems: 'id',
    options: 'id',
    categoryRank: 'id',
    marketingCategories: 'name',
  };

  const formatData = (cell: any, row?: any) => {
    if (useDate.includes(cell.column.Header))
      return cell.value
        ? moment(cell.value as MomentInput).format('Do MMM YYYY, H:mm')
        : '-';

    if (cell.column.Header === 'Status')
      return (
        <div className="flex items-center space-x-4 uppercase">
          <div
            className={`h-2 w-2 rounded-full mr-2 ${
              statusColors[cell.value as keyof typeof statusColors] || 'bg-pink'
            }`}
          />
          {cell.value?.split('_').join(' ')}
        </div>
      );

    if (cell.column.Header === 'Is Available?')
      return (
        <Toggle
          active={
            row.original.status === 'ACTIVE' || row.original.status === 'OPEN'
              ? true
              : false
          }
          module={tableFor}
          primaryKey={row.original.id}
          key={row.original.id}
          item={row.original}
        />
      );

    if (cell.column.Header === 'Item available?')
      return (
        <Toggle
          active={row.original.active}
          module={tableFor}
          primaryKey={row.original.id}
          key={row.original.id}
          item={row.original}
        />
      );

    if (cell.column.Header === 'Inactive' && isOwner)
      return (
        <CheckboxToggle
          active={row.original.status === 'INACTIVE' ? false : true}
          primaryKey={row.original.id}
          key={row.original.id}
          module={tableFor}
        />
      );

    if (cell.column.Header === 'Payment Status')
      return (
        <div className="flex items-center space-x-4 uppercase">
          <div
            className={`h-2 w-2 rounded-full mr-2 ${
              statusColors[
                cell.value === true
                  ? 'Paid'
                  : ('Pending' as keyof typeof statusColors)
              ]
            }`}
          />
          {cell.value === true ? 'Paid' : 'Pending'}
        </div>
      );

    if (cell.column.Header === 'Quick Actions')
      return (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            quickAction(e, row.original.id, tableFor);
          }}
        >
          <img className="h-6" src={quickActionIcon} alt="quick-action" />
        </button>
      );

    if (cell.column.Header === 'Price')
      return `₦${numeral(row.original.basePrice || row.original.price).format(
        '0,0'
      )}`;

    if (cell.column.Header === 'Option price')
      return `₦${numeral(getOptionPrice(row.original)).format('0,0')}`;

    if (cell.column.Header === 'Duplicate' && duplicateMethod)
      return (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            duplicateMethod(row.original.id);
          }}
        >
          <img className="h-6" src={duplicate} alt="duplicate" />
        </button>
      );

    if (cell.column.Header === 'Delete' && deleteMethod)
      return (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            deleteMethod(row.original.id);
          }}
        >
          <img className="h-6" src={deleteIcon} alt="duplicate" />
        </button>
      );

    return cell.render('Cell');
  };

  useEffect(() => {
    setSelectedRows && setSelectedRows(selectedRowIds);
  }, [setSelectedRows, selectedRowIds]);

  return (
    <div className={containerClassName || 'w-full mt-16 overflow-y-auto'}>
      {!paginated && showSearch && (
        <div className="flex items-center space-x-3 justify-end">
          <input
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              setGlobalFilter(e.target.value);
            }}
            placeholder="Search Table.."
            className="border border-neutral-300 px-3 py-2 shadow-xsmall focus:outline-none rounded-lg"
          />
        </div>
      )}
      <div className="overflow-x-scroll block">
        <table
          {...getTableProps()}
          className="border-spacing-y-[1px] border-separate mt-5 w-full"
        >
          <thead className="text-gray-800 uppercase text-xs tracking-[1px]">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    <th
                      className="semibold text-start pb-10 px-6"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render('Header')}
                      <span>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  className={`cursor-pointer text-xs xl:text-sm relative ${
                    tableFor === 'orders' && shouldFlag(row.original as IOrder)
                      ? 'bg-error-50'
                      : 'bg-white'
                  }`}
                  onClick={(e) =>
                    openPage
                      ? openPage(
                          openParam[tableFor as keyof typeof openParam]
                            ? (row.original as any)[
                                openParam[tableFor as keyof typeof openParam]
                              ]
                            : row.original,
                          e
                        )
                      : e.preventDefault()
                  }
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    return cell.column.Header !== 'Action' &&
                      cell.column.Header !== 'Edit' ? (
                      <td
                        className="px-3 sm:px-6 py-5 border-b border-neutral-200"
                        {...cell.getCellProps()}
                      >
                        <div className="flex items-center">
                          {withLogo.includes(cell.column.Header as string) && (
                            <img
                              src={
                                (row.original as any).logoUrl ||
                                (row.original as any).imageUrl
                              }
                              className="hidden sm:block h-8 w-8 rounded-full object-cover overflow-hidden bg-gray-300 mr-4 flex-shrink-0"
                              alt="logo"
                            />
                          )}
                          {tableFor === 'orders' && index === 0 && (
                            <OrderFlag order={row.original as IOrder} />
                          )}
                          {formatData(cell, row)}
                        </div>
                      </td>
                    ) : (
                      <td
                        className="px-3 sm:px-6 py-5 border-b border-neutral-200"
                        key={cell.value || Math.random() + i}
                      >
                        <div className="flex items-center space-x-4">
                          {/* <img src={deleteIcon} alt="delete" /> */}
                          <button
                            onClick={(e) =>
                              editMethod(
                                (row.original as any)[
                                  editParam[tableFor as keyof typeof editParam]
                                ],
                                e
                              )
                            }
                          >
                            <img src={edit} alt="edit" />
                          </button>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
