import { stringifyParameters } from '../helpers';
import { ICouponsApi } from '../types';
import { formDataInstance, instance, v2Instance } from './instance';

export const couponsApi: ICouponsApi = {
  getAllCoupons: async (params) => {
    const payload = stringifyParameters(params);

    const data = await v2Instance.get(`/admin/coupons/${payload}`);
    return data.data;
  },

  getCoupon: async (slug, params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/admin/coupons/${slug}${payload}`);
    return data;
  },

  getCouponUses: async (slug, params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(
      `/admin/coupons/${slug}/coupon-uses${payload}`
    );
    return data;
  },

  createCoupon: async (payload) =>
    await instance.post('/admin/coupons/', payload),

  updateCoupon: async (payload, id) =>
    await instance.put(`/admin/coupons/${id}`, payload),

  bulkUploadCoupon: async (payload, code) =>
    await formDataInstance.post(`/coupons/${code}/bulk-create`, payload),

  getActiveCashback: async () =>
    (await formDataInstance.get(`/admin/cashback-config/active`)).data.data,

  getCashbackTransactions: async (params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(
      `/admin/cashback-config/transactions${payload}`
    );
    return data.data;
  },

  createCashback: async (payload) =>
    await instance.post(`/admin/cashback-config`, payload),

  updateCashback: async (payload) =>
    await instance.put(`/admin/cashback-config`, payload),

  getAllBudgets: async () => (await instance.get(`/budget`)).data.data,
};
