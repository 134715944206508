import { stringifyParameters } from '../helpers';
import { IBrandApi } from '../types';
import { instance } from './instance';

export const brandApi: IBrandApi = {
  getAllBrands: async () => await instance.get('/brand/all/'),

  getBrand: async (slug = 'all') =>
    (await instance.get(`/brand/${slug}/`)).data.data,

  createBrand: async (payload) =>
    await instance.post('/admin/brand/create/', payload),

  updateBrand: async (payload, id) =>
    await instance.put(`/admin/brand/${id}`, payload),

  getBrandGroups: async (id) => await instance.get(`/category/rank/${id}`),

  createGroup: async (payload) =>
    await instance.post(`/admin/group/create/`, payload),

  updateGroup: async (payload, id) =>
    await instance.put(`/admin/group/${id}`, payload),

  getGroup: async (slug) => await instance.get(`/group/${slug}`),

  getBrandMeals: async (slug) => await instance.get(`/brand/${slug}/meals`),

  getAllGroups: async () => await instance.get(`/group/all/`),

  getBrandCategories: async (id) => await instance.get(`/category/brand/${id}`),

  getBrandCategoryRanks: async (id) =>
    await instance.get(`/category/rank/${id}`),

  getBrandReviews: async (id) => await instance.get(`/review/brand/${id}`),

  exportReviews: async (params) => {
    const payload = stringifyParameters(params);

    const data = await instance.get(`/review/csv${payload}`);

    return data.data;
  },

  createBrandPosition: async (payload) =>
    await instance.post('/admin/brand-positioning', payload),

  getBrandPositions: async (postionId) =>
    await instance.get(`/admin/brand-positioning/brand/${postionId}`),

  updateBrandPosition: async (id, payload) =>
    await instance.put(`/admin/brand-positioning/${id}`, payload),

  deleteBrandPosition: async (id) =>
    await instance.delete(`/admin/brand-positioning/${id}`),
};
